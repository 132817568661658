<template>
  <banded-section
    class="AuthenticateTwitter"
    sidebar-title="Authenticate Twitter Profiles"
  >
    <heading size="3">
      Authenticate Twitter Profiles
    </heading>
    <table
      v-loading="isFetchingProfiles"
      class="table is-striped is-hoverable is-fullwidth"
    >
      <thead>
        <tr>
          <th>Profile</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="profile in profiles"
          :key="profile.id"
        >
          <td>{{ profile.profile_screen_name }}</td>
          <td>
            <v-button
              v-if="!profile.token_issued_at"
              :loading="isLoading === profile.id"
              class="is-success"
              @click="authenticate(profile)"
            >
              Authenticate
            </v-button>
            <v-button
              v-else
              class="is-plain is-dark"
              disabled
            >
              Authenticated
            </v-button>
          </td>
        </tr>
      </tbody>
    </table>
  </banded-section>
</template>

<script>
import { openWindow, pollPopupForParameter } from '@/utils'

/**
 * Allows for staff members to Authenticate with different Twitter profiles
 * @module Staff/AuthenticateTwitter
 */
export default {
  name: 'AuthenticateTwitter',
  data () {
    return {
      profiles: [],
      isLoading: null,
      isFetchingProfiles: false
    }
  },
  mounted () {
    this.fetchTwitterProfiles()
  },
  methods: {
    fetchTwitterProfiles () {
      this.isFetchingProfiles = true
      this.$api.get('staff/twitter-profiles')
        .then((response) => {
          this.profiles = response.data.data
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isFetchingProfiles = false
        })
    },
    async authenticate (profile) {
      this.isLoading = profile.id
      try {
        const response = await this.$api.get('staff/oauth/twitter/authorize')
        const popup = openWindow(response.data.data.authorize_url)
        await pollPopupForParameter(popup, { search: '?token_received=true' })
        this.fetchTwitterProfiles()
      } catch (err) {
        let skipReport = false
        // TODO: Clean this up with dedicated error instances at some point
        if (err.message && (err.message.includes('User closed window') || err.message.includes('Please enable popups'))) {
          skipReport = true
        }
        this.$displayRequestError(err, this.$t('errors.error'), undefined, skipReport)
      } finally {
        this.isLoading = null
      }
    }
  }
}
</script>
